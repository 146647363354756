import React, { useContext } from 'react';
import { ThemeContext } from '../providers/ThemeProvider.js';

const ToggleButton = () => {
    const { darkTheme, toggleTheme } = useContext(ThemeContext);

    return (
        <label className="switch">
            <input type="checkbox" checked={darkTheme} onChange={toggleTheme} />
            <span className="slider round"></span>
        </label>
    );
};

export default ToggleButton;