import React, { createContext, useState, useMemo } from 'react';

// Creación de contextos individuales
export const LanguageContext = createContext();
export const TransLanguageContext = createContext();
export const SpeakerContext = createContext();
export const TranscriptsContext = createContext();
export const TranslationsContext = createContext();
export const AudioContext = createContext();

// Componente LanguageProvider
const LanguageProvider = ({ children, userLanguageCode }) => {
    const [language, setLanguage] = useState(userLanguageCode || 'en'); // Inicializa con el idioma del usuario si está disponible

    const contextValue = useMemo(() => ({
        language,
        setLanguage
    }), [language]);

    return (
        <LanguageContext.Provider value={contextValue}>
            {children}
        </LanguageContext.Provider>
    );
};


// Componente TransLanguageProvider
const TransLanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('English'); 

    const contextValue = useMemo(() => ({
        language,
        setLanguage
    }), [language]);

    return (
        <TransLanguageContext.Provider value={contextValue}>
            {children}
        </TransLanguageContext.Provider>
    );
};



// Componente SpeakerProvider
const SpeakerProvider = ({ children }) => {
    const [speaker, setSpeaker] = useState('alloy');

    const contextValue = useMemo(() => ({
        speaker,
        setSpeaker
    }), [speaker]);

    return (
        <SpeakerContext.Provider value={contextValue}>
            {children}
        </SpeakerContext.Provider>
    );
};

// Componente TranscriptsProvider
const TranscriptsProvider = ({ children }) => {
    const [transcripts, setTranscripts] = useState([]);

    const updateTranscript = (newTranscript) => {
        setTranscripts(prevTranscripts => {
            if (!prevTranscripts.includes(newTranscript)) {
                return [...prevTranscripts, newTranscript];
            } else {
                return prevTranscripts;
            }
        });
    };

    const contextValue = useMemo(() => ({
        transcripts, 
        updateTranscript
    }), [transcripts]);

    return (
        <TranscriptsContext.Provider value={contextValue}>
            {children}
        </TranscriptsContext.Provider>
    );
};

// Componente TranslationsProvider
const TranslationsProvider = ({ children }) => {
    const [translatedTexts, setTranslatedTexts] = useState([]);

    const updateTranslated = (newTranslation) => {
        setTranslatedTexts(prevTranslations => {
            if (!prevTranslations.includes(newTranslation)) {
                return [...prevTranslations, newTranslation];
            } else {
                return prevTranslations;
            }
        });
    };

    const contextValue = useMemo(() => ({
        translatedTexts, 
        updateTranslated
    }), [translatedTexts]);

    return (
        <TranslationsContext.Provider value={contextValue}>
            {children}
        </TranslationsContext.Provider>
    );
};

const AudioProvider = ({ children }) => {
    const [audioSources, setAudioSources] = useState([]);

    const addAudioSource = (newSource) => {
        setAudioSources(prevSources => [...prevSources, newSource]);
    };

    const contextValue = useMemo(() => ({
        audioSources, 
        addAudioSource
    }), [audioSources]);

    return (
        <AudioContext.Provider value={contextValue}>
            {children}
        </AudioContext.Provider>
    );
};



export { TransLanguageProvider, LanguageProvider, SpeakerProvider, TranscriptsProvider, TranslationsProvider, AudioProvider };
