import React, { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider.js';
import UserMongoDB from './UserMongoDB.js';
import { Link } from 'react-router-dom';


const UserProfile = () => {
  const { user, canPerformActions } = useContext(UserContext); // Usar pointsConsumed de UserContext si es aplicable

  if (!user) {
    return <div className='welcome'>Loading user info...</div>;
  } 

  return (
    <>
      <UserMongoDB />
      <div className='welcome'>
        <h2>Welcome, {user.name}!</h2>
        {user.subscriptionStatus === 'premium' && <div className='subscriptionStatus'><p>Nomad User</p></div>}
        {user.subscriptionStatus === 'plata' && <div className='subscriptionStatus'><p>Pioneer User</p></div>}
        {user.subscriptionStatus === 'free' && <div className='subscriptionStatus'><p>Explorer(Free) User</p></div>}
        <div>
          <p>Your Points: {user.points}</p>
          {!canPerformActions && <p className='alertpoints'>Sorry, you do not have enough points to perform actions. To continue, please change your subscription <Link to="/suscriptions">HERE</Link>.</p>}
        </div>
      </div>
    </>
  );
};

export default UserProfile;
