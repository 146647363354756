import React, { createContext, useState, useEffect, useCallback,useMemo } from 'react';
import { UserPointsContext } from './UserPointsContext.js';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import { auth } from '../firebase/firebase.js';
import { deductPoints } from '../componentes/users/pointUtils.js'; 

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  // Estados relacionados con el usuario
  const [user, setUser] = useState(null);
  const [canPerformActions, setCanPerformActions] = useState(true);

  // Estados relacionados con los puntos
  const [pointsConsumed, setPointsConsumed] = useState(0);
  const [pointsToDeduct, setPointsToDeduct] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [sessionPoints, setSessionPoints] = useState(0);

  const debounceTime = 5000; // 5 segundos para el debounce

  // Función para obtener datos de MongoDB
  const fetchUserData = async (uid) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL; 
      const response = await fetch(`${apiUrl}/api/getUserData?uid=${uid}`);
    
      if (!response.ok) {
        throw new Error('Error al obtener datos del usuario');
      }
    
      const data = await response.json();
      return data.user; // Asegúrate de devolver solo la parte de 'user' del objeto
    } catch (error) {
      console.error('Error en la llamada a la API:', error);
      return null;
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        try {
          const { uid } = authUser;
          const db = getFirestore();
          const userRef = doc(collection(db, 'users'), uid);
          const docSnap = await getDoc(userRef);

          let combinedUserData = {};
          
          if (docSnap.exists()) {
            const firestoreData = docSnap.data();
            combinedUserData = { ...firestoreData, uid };

            // Intenta obtener datos adicionales de MongoDB
            const mongodbData = await fetchUserData(uid);
            if (mongodbData) {
              combinedUserData = { ...combinedUserData, ...mongodbData };
            }
          }

          setUser(combinedUserData);
        } catch (error) {
          console.error('Error getting document:', error);
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Actualizar canPerformActions basándose en user.points y pointsToDeduct
    const pointsNeeded = 0.1;
    if (user && user.points - pointsToDeduct < pointsNeeded) {
      setCanPerformActions(false);
    } else {
      setCanPerformActions(true);
    }
  }, [user, pointsToDeduct]);

  useEffect(() => {
    // Debounce effect para la deducción de puntos
    const handler = setTimeout(() => {
      if (pointsToDeduct > 0 && user) {
        console.log(`Deduciendo ${pointsToDeduct} puntos acumulados del usuario: ${user.uid}`);

        deductPoints(user.uid, pointsToDeduct)
          .then(newPoints => {
            console.log(`Resultado de deductPoints:`, newPoints);
            if (typeof newPoints === 'number') {
              console.log(`Actualizando puntos consumidos localmente a ${newPoints}`);
              setPointsConsumed(newPoints);
              // Aquí actualizamos el estado 'user' con los nuevos puntos
              setUser(prevUser => ({ ...prevUser, points: newPoints }));
            } else {
              console.error("newPoints no es un número, puntos no actualizados");
            }
          })
          .catch(error => {
            console.error("Error al actualizar los puntos:", error);
          });

        setPointsToDeduct(0); // Resetear el acumulador de puntos a deducir
      }
    }, debounceTime);

    return () => clearTimeout(handler);
  }, [pointsToDeduct, user, setPointsConsumed, setUser]);

  
  const updateUserPoints = useCallback(async (cost) => {
    if (!user) {
      console.error("Error: No hay usuario definido.");
      return;
    }

    const calculatedPoints = cost / 0.10; // Suponiendo que 1 punto = USD 0.10 de costo
    console.log(`[updateUserPoints] Acumulando ${calculatedPoints} puntos para deducir del usuario: ${user.uid}`);
    setPointsToDeduct(prevPoints => prevPoints + calculatedPoints);
    setTotalCost(prevCost => prevCost + cost);
    setSessionPoints(prevPoints => prevPoints + calculatedPoints);
  }, [user]);


  // Memoizar los valores para evitar renderizaciones innecesarias
  const userContextValue = useMemo(() => ({
    user,
    canPerformActions,
    setUser,
    setCanPerformActions
  }), [user, canPerformActions]);

  const userPointsContextValue = useMemo(() => ({
    pointsConsumed,
    pointsToDeduct,
    totalCost,
    sessionPoints,
    setPointsConsumed,
    setPointsToDeduct,
    setTotalCost,
    setSessionPoints,
    updateUserPoints,
  }), [pointsConsumed, pointsToDeduct, totalCost, sessionPoints, updateUserPoints]);

  return (
    <UserContext.Provider value={userContextValue}>
      <UserPointsContext.Provider value={userPointsContextValue}>
        {children}
      </UserPointsContext.Provider>
    </UserContext.Provider>
  );
};

export default UserProvider;
