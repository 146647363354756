import React, { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider.js';
import { Container, Row, Col } from 'react-bootstrap';
import ToggleButton  from '../ToggleButton.js';


const UserDashboard = () => {
  const { user }  = useContext(UserContext);

  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <Container className="mt-4 mb-4">
      <h2>Dashboard</h2>

      <Row>
        {/* Column for Personal Information and User Data */}
        <Col md={6}>
          <div>
            <h3>Personal Information</h3>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Display Name:</strong> {user.name}</p>
          </div>
          <div>
            <h3>User Data</h3>
            {user.userdata && user.userdata.map((data, index) => (
              <div key={index}>
                <p><strong>Language:</strong> {user.userdata[0].languageName}, {user.userdata[0].languageCode}</p>
              </div>
            ))}
          </div>
          <div>
            <h3>Toggle Theme Colors</h3>
            <ToggleButton />
          </div>
        </Col>

        {/* Column for Favorites */}
        <Col md={6}>
          <div>
            <h3>Favorites</h3>
            {user.favorites && user.favorites.map((fav, index) => (
              <div className='contenedortraddashboard' key={index}>
                <p className='transtext'><strong>Transcript:</strong> {fav.transcript}</p>
                <p className='translatedtext'><strong>Translation:</strong> {fav.translation}</p>
                <div className='audiocontainer'>
                  <audio controls preload="auto">
                            <source src={fav.audioUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDashboard;
