import { useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../providers/UserProvider.js';

const UserMongoDB = () => {
  const { user }  = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const addUserToMongoDB = async () => {
      if (user) {
        try {
          // Primero, verifica si el usuario ya existe en MongoDB
          const checkUserResponse = await axios.get(`${apiUrl}/api/checkUser`, {
            params: { uid: user.uid }
          });

          // Si el usuario no existe, entonces crea uno nuevo
          if (!checkUserResponse.data.exists) {
            const createUserResponse = await axios.post(`${apiUrl}/api/createUser`, {
              uid: user.uid,
              email: user.email,
              displayName: user.name,
            });

            if (createUserResponse.data.success) {
              console.log('Usuario agregado a MongoDB');
            } else {
              console.error('No se pudo agregar el usuario a MongoDB');
            }
          } else {
            console.log('El usuario ya existe en MongoDB');
          }
        } catch (error) {
          console.error('Error en la verificación o creación del usuario:', error);
        }
      }
    };

    addUserToMongoDB();
  }, [user, apiUrl]);

  return null; // No renderizar nada en pantalla
};

export default UserMongoDB;
