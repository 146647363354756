import React, { createContext, useState, useCallback } from 'react';

export const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
    const [favorites, setFavorites] = useState(new Set());

    // Función para actualizar los favoritos
    const updateFavorites = useCallback((favoriteId) => {
        setFavorites((prevFavorites) => {
            const newFavorites = new Set(prevFavorites);
            if (newFavorites.has(favoriteId)) {
                newFavorites.delete(favoriteId);
            } else {
                newFavorites.add(favoriteId);
            }
            return newFavorites;
        });
    }, []);

    return (
        <FavoritesContext.Provider value={{ favorites, updateFavorites }}>
            {children}
        </FavoritesContext.Provider>
    );
};
