import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import UserNavBar from './componentes/navigation/UserNavBar.js';
import UserRoutes from './componentes/routes/UserRoutes.js';
import { ThemeContext } from '././providers/ThemeProvider.js';

function App() {
  const { darkTheme } = useContext(ThemeContext);

  return (
    <Router>
      <div className={darkTheme ? 'tema-oscuro' : 'tema-claro'}>
        <div className="App">
          <UserNavBar />
          <UserRoutes />
        </div>
      </div>
    </Router>
  );
}

export default App;
