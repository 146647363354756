import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaMicrophone, FaHands } from 'react-icons/fa/index.esm.js';
import { Button } from 'react-bootstrap';
import { UserContext } from '../../providers/UserProvider.js';
import useAuth from '../hooks/useAuth.js';
import UserProfile from '../users/UserProfile.js';


function NavigationList({ user, toggleSidebar }) {
  
  return (
    <ul className="navbar-nav">
      {user && user.subscriptionStatus === 'premium' && (
        <>
        <li className="nav-item glowing">
            <Link to="/" onClick={toggleSidebar} className="nav-link">
              <FaMicrophone className="me-2" /> Voice Nomad
            </Link>
          </li>
        <li className="nav-item glowing">
            <Link to="/vision" onClick={toggleSidebar} className="nav-link">
              <FaEye className="me-2" /> Vision Nomad
            </Link>
          </li>
          <li className="nav-item">
          <Link to="/manual" onClick={toggleSidebar} className="nav-link">
              <FaHands className="me-2" /> Manual Nomad
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/favorites" onClick={toggleSidebar} className="nav-link">Favorites</Link>
          </li>
          <li className="nav-item">
            <Link to="/suscriptions" onClick={toggleSidebar} className="nav-link">Suscriptions</Link>
          </li>
          <li className="nav-item">
            <Link to="/privacy" onClick={toggleSidebar} className="nav-link">Privacy Policy</Link>
          </li>
          <li className="nav-item">
            <Link to="/faq" onClick={toggleSidebar} className="nav-link">FAQ</Link>
          </li>
        </>
      )}

      {user && user.subscriptionStatus === 'plata' && (
        <>
          <li className="nav-item glowing">
            <Link to="/" onClick={toggleSidebar} className="nav-link">Voice Nomad</Link>
          </li>
          <li className="nav-item">
            <Link to="/favorites" onClick={toggleSidebar} className="nav-link">Favorites</Link>
          </li>
          <li className="nav-item">
            <Link to="/suscriptions" onClick={toggleSidebar} className="nav-link">Suscriptions</Link>
          </li>
          <li className="nav-item">
            <Link to="/privacy" onClick={toggleSidebar} className="nav-link">Privacy Policy</Link>
          </li>
          <li className="nav-item">
            <Link to="/faq" onClick={toggleSidebar} className="nav-link">FAQ</Link>
          </li>
        </>
      )}

      {user && user.subscriptionStatus === 'free' && (
        <>
          <li className="nav-item glowing">
            <Link to="/" onClick={toggleSidebar} className="nav-link">Voice Nomad</Link>
          </li>
          <li className="nav-item">
            <Link to="/suscriptions" onClick={toggleSidebar} className="nav-link">Suscriptions</Link>
          </li>
          <li className="nav-item">
            <Link to="/privacy" onClick={toggleSidebar} className="nav-link">Privacy</Link>
          </li>
          <li className="nav-item">
            <Link to="/faq" onClick={toggleSidebar} className="nav-link">FAQ</Link>
          </li>
        </>
      )}

    </ul>
  );
}

function UserNavBar() {
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { user }  = useContext(UserContext);
  const { handleLogOut } = useAuth();

  function toggleSidebar() {
    setSidebarOpen(prev => !prev);
  }

  // Función para manejar el click en el botón Dashboard
  function handleDashboardClick() {
    toggleSidebar(); // Esto cierra la barra lateral
    navigate('/dashboard'); // Esto navega a la página del dashboard
  }

  return (
    <>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <UserProfile/>
        <Button onClick={handleDashboardClick}>Dashboard</Button>
        <Button onClick={handleLogOut}>Logout</Button>

        <NavigationList user={user} toggleSidebar={toggleSidebar} />
      </div>

      {/* Capa oscura */}
      {isSidebarOpen && (
        <div className="overlay" onClick={toggleSidebar}></div>
      )}

      <header>
        <div className="header">
          <div className="header-left">
          <Link  to="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/nomad_logo.svg`}
              width="180"
              height="auto"
              className="d-inline-block align-top"
              alt="Nomad logo"
            />
            {' '}
          </Link>
          </div>
          <div className="header-right d-flex justify-content-between">
            <div className={`burger-menu ${isSidebarOpen ? 'active' : ''}`} onClick={toggleSidebar}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default UserNavBar;
