import { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

const apiUrl = process.env.REACT_APP_API_URL;

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
      setLoggedInUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogOut = useCallback(async (event) => {
    event.preventDefault();
    const auth = getAuth();
    const userId = auth.currentUser ? auth.currentUser.uid : null;

    if (!userId) {
      console.error('No user ID found during logout');
      return;
    }

    try {
      await fetch(`${apiUrl}/api/cleanUpTempFiles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId })
      });

      await signOut(auth);
      setIsLoggedIn(false);
      setLoggedInUser(null);
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  }, []);

  return { isLoggedIn, loggedInUser, setLoggedInUser, handleLogOut };
};

export default useAuth;
