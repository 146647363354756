import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import {LanguageProvider} from './providers/TranslatorProviderSeparate.js';
import {TransLanguageProvider} from './providers/TranslatorProviderSeparate.js';
import {SpeakerProvider} from './providers/TranslatorProviderSeparate.js'; 
import {TranscriptsProvider} from './providers/TranslatorProviderSeparate.js'; 
import {TranslationsProvider} from './providers/TranslatorProviderSeparate.js'; 
import {AudioProvider} from './providers/TranslatorProviderSeparate.js'; 
import { FavoritesProvider } from './providers/FavoritesContext.js';
import { ThemeProvider } from './providers/ThemeProvider.js';


import UserProvider from './providers/UserProvider.js'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <LanguageProvider>
        <TransLanguageProvider>
          <SpeakerProvider>
            <TranscriptsProvider>
              <TranslationsProvider>
                <AudioProvider>
                  <FavoritesProvider>
                    <ThemeProvider>
                      <App />
                    </ThemeProvider>  
                  </FavoritesProvider>  
                </AudioProvider>  
              </TranslationsProvider> 
            </TranscriptsProvider>  
          </SpeakerProvider>
        </TransLanguageProvider>    
      </LanguageProvider>    
    </UserProvider>
  </React.StrictMode>
);


